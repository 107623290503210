import React from 'react';
import classes from './HomepageBody.module.scss';

export default () => (
  <div className="container">
    <p className={classes.homeText}></p>

    {/* <div className={classes.boxesContainer}>
      <Card
        label="Complete Gym & SPA solutions"
        body="Bespoke wellbeing and fitness solutions including gym, pool, sauna, steam and spa design and fit out, facility management and gym & pool maintenance. We also deliver fitness services to residents, employees and individuals. We work within the residential, corporate, home, student, PRS and education sectors."
      />
      <Card
        label="Gym & Spa and Installation"
        body="We are established market leaders in the design & installation of residential and corporate health and fitness facilities, fitting everything from bespoke home gyms, training studios, spas, pools and large scale projects."
      />
      <Card
        label="Wellbeing & Fitness"
        body="Whether its personal fitness goals that drive you or you need health and fitness solutions for your residents or workforce, we have a team of professional, qualified trainers who can devise a bespoke service to fit with your budget and requirements."
      />
    </div> */}
  </div>
);
