import React from 'react';
import classes from '../../components/Contact/Contact.module.scss';
import Facebook from 'grommet/components/icons/base/SocialFacebook';
import Instagram from 'grommet/components/icons/base/SocialInstagram';
import Twitter from 'grommet/components/icons/base/SocialTwitter';

export default () => (
  <div className={'container'}>
    <div className={classes.detailsContainer}>
      <div className={classes.contactDetails}>
        <div className={classes.singleDetail}>
          <span className={classes.label}>Address:</span>
          <div>
            Lodge Lane
            <br />
            ElstonNewark, Nottinghamshire NG23 5PG
            <br />
          </div>
        </div>

        <div className={classes.singleDetail}>
          <span className={classes.label}>Telephone number:</span>
          01283 576522
        </div>
      </div>

      <div className={classes.iconsContainer}>
        <a
          href="https://www.facebook.com/EdenHallSpa"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.contactIcon}>
          <Facebook size="large" className={classes.icons} />
        </a>

        <a
          href="https://www.instagram.com/edenspanotts"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.contactIcon}>
          <Instagram size="large" className={classes.icons} />
        </a>

        <a
          href="https://twitter.com/edenspanotts"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.contactIcon}>
          <Twitter size="large" className={classes.icons} />
        </a>
      </div>
    </div>
  </div>
);
